<template>
  <div id="AI" class="ai">
    <h1 class="ai-title">CREATE AND MINT AI GENERATED ART</h1>
    <!-- <hr /> -->
    <div class="ai-content">
      <!-- <div class="top">
        <div class="image-section">
          <img src="/img/threespace/ai/ai-how-to-use.png" />
        </div>
      </div> -->
      <div class="left">
        <div class="ai-prompt">
          <div class="ai-small-title">PROMPT</div>
          <div class="input-wrap">
            <textarea
              type="text"
              class="mb-0"
              placeholder="Type your prompt e.g., White, Cute, Cat"
              v-model="aiPrompt"
            />
          </div>
        </div>
        <div class="ai-type-wrap">
          <div class="ai-type">
            <div class="ai-small-title">
              {{ $t("ai.aiModel") }}
            </div>
            <select-section
              :options="modelOptions"
              :defaultOptionText="$t('ai.defaultModel')"
              @select="modelSelect"
            />
          </div>
        </div>
        <div class="ai-type-wrap">
          <div class="ai-type">
            <div class="ai-small-title">
              {{ $t("ai.canvasSize") }}
            </div>
            <select-section
              :options="canvasOptions"
              :defaultOptionText="$t('ai.defaultCanvas')"
              @select="canvasSelect"
            />
          </div>
        </div>
        <div class="create-btn">
          <div>
            <Button :label="$t('ai.create')" :onClick="generateAI" />
          </div>
        </div>
      </div>
      <div class="right">
        <div class="ai-result" v-if="imageSrc.length > 0 || isBusy">
          <div class="ai-small-title">
            {{ $t("ai.result") }}
          </div>
          <div class="ai-result-box" v-if="imageSrc.length > 0 || isBusy">
            <template v-for="(src, index) in imageSrc">
              <div class="loader-wrap" :key="index">
                <div class="loader-dummy" v-if="isLoading[index]">
                  <div class="loader" />
                </div>
                <img :src="src" alt="Image" v-else />
              </div>
            </template>
          </div>
          <div class="ai-result-box-none" v-else>No result</div>
        </div>
        <div class="title">
          <span>{{ $t("ai.recentCreations") }}</span>
        </div>
        <div class="img-wraps" v-if="aiImg.length > 0">
          <template v-for="(src, index) in aiImg">
            <div :key="index">
              <img
                :src="replaceDisplayCardUrl(src)"
                alt="Image"
                :key="index"
                @click="showProductDetail(src.idx)"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
    <WalletPopup ref="walletPopupModal" @wallet-info="onComplete" />
  </div>
</template>

<script>
import SelectSection from "@/components/Selection";
import { createNamespacedHelpers, mapActions, mapGetters } from "vuex";
import WalletPopup from "@/views/threespace/WalletPopup";
import Button from "./components/common/Button.vue";
const aiHelper = createNamespacedHelpers("ai");
const galleryHelper = createNamespacedHelpers("gallery");
export default {
  components: {
    SelectSection,
    WalletPopup,
    Button,
  },
  name: "AI",
  metaInfo() {
    return {
      title: this.$t("meta.drops.title") + " | 3space Art",
      meta: [
        {
          vmid: "title",
          name: "title",
          content: this.$t("meta.drops.title") + " | 3space Art",
        },
        {
          vmid: "description",
          name: "description",
          content: this.$t("meta.drops.description"),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("meta.drops.title") + " | 3space Art",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.$t("meta.drops.description"),
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.origin + this.$route.path,
        },
      ],
    };
  },
  data: function () {
    return {
      aiPrompt: "",
      imageSrc: [],
      aiImg: [],
      canvasOptions: [
        {
          value: { id: "default", desc: "desc" },
          text: this.$t("ai.defaultCanvas"),
        },
      ],
      modelOptions: [
        {
          value: { id: "default", desc: "desc" },
          text: this.$t("ai.defaultModel"),
        },
      ],
      selectedCanvas: {
        value: { id: "default", desc: "desc" },
        text: this.$t("ai.defaultCanvas"),
      },
      selectedModel: {
        value: { id: "default", desc: "desc" },
        text: this.$t("ai.defaultCanvas"),
      },
      visibleSortBy: false,
      isBusy: false,
      isLoading: [],
    };
  },
  mixins: [],
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },
  mounted() {
    this.$nextTick(() => {
      this.resetGalleryProducts();
      this.requestGalleryProductItems();
    });
  },
  methods: {
    ...mapActions("wallet", ["walletLogin"]),
    ...aiHelper.mapActions(["generateImage"]),
    ...galleryHelper.mapActions([
      "getGalleryProductsDrops",
      "setGalleryPreviousParams",
    ]),
    ...galleryHelper.mapMutations(["resetGalleryProducts"]),
    requestGalleryProductItems() {
      this.getGalleryProductsDrops({
        page: 0,
        size: 20,
        keywordCluster: ["AI"],
        keywordOnlyOrdinals: false,
      })
        .then((data) => {
          if (data.content.length > 0) {
            const randomArray = this.getRandomOfArray(data.content, 6);
            for (let item of randomArray) {
              this.aiImg.push(item);
            }
          }
        })
        .catch((error) => {
          this.$log.error(error);
        });
    },
    getRandomOfArray(array, size) {
      const subset = [];
      const arrayCopy = array.slice();

      for (let i = 0; i < size; i++) {
        const randomIndex = Math.floor(Math.random() * arrayCopy.length);
        subset.push(arrayCopy.splice(randomIndex, 1)[0]); // Remove and add random element to subset
      }

      return subset;
    },
    showProductDetail(idx) {
      this.$router.push("/art/" + idx);
    },
    showSortByFilter(value) {
      if (typeof value === "boolean") this.visibleSortBy = value;
      else {
        this.visibleSortBy = !this.visibleSortBy;
      }
    },
    modelSelect(item) {
      this.selectedModel = item;
    },
    canvasSelect(item) {
      this.selectedCanvas = item;
    },
    replaceDisplayCardUrl(item) {
      if (
        item.displayCardFile !== undefined &&
        item.displayCardFile !== null &&
        item.displayCardFile !== "" &&
        item.displayCardFile === "THREESPACE"
      ) {
        return item.displayCardUrlThreespace; // 3space S3 URL
      } else {
        if (
          item.displayCardUrl !== undefined &&
          item.displayCardUrl.startsWith("https://ipfs.io/")
        ) {
          return item.displayCardUrl.replace(
            "https://ipfs.io/",
            "https://ipfs.3space.art/"
          );
        }
        return item.displayCardUrl;
      }
    },
    async generateAI() {
      if (!this.isAuthenticated) {
        this.$refs["walletPopupModal"].login();
      } else {
        if (!this.isBusy) {
          this.isBusy = true;
          const loaderImg = "";
          this.imageSrc.unshift(loaderImg);
          this.isLoading.unshift(true);

          console.log(this.aiPrompt);
          const data = await this.generateImage({ prompt: this.aiPrompt });
          this.$set(this.imageSrc, 0, `data:image/png;base64,${data}`);
          this.isLoading[0] = false;

          this.isBusy = false;
        }
      }
    },
    onComplete(data) {
      if (!this.isAuthenticated) {
        this.walletLogin(data);
      } else {
        this.changeWallet(data);
      }
    },
  },
  filters: {},
};
</script>
